import { FastAverageColor } from 'fast-average-color';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { GamePlayScoreboardTextHeader } from '../../components/Game/Blocks/Common/GamePlay/GamePlayScoreboardText';
import { rgbToHsl } from '../../utils/css';

function initDrop(area: HTMLElement, onFile: (fileAsDataUrl: string) => void) {
  area.addEventListener(
    'dragover',
    function (evt) {
      evt.preventDefault();
    },
    false
  );

  area.addEventListener(
    'drop',
    function (evt) {
      if (!evt.dataTransfer?.files.length) return;

      for (let i = 0; i < evt.dataTransfer.files.length; i++) {
        const file = evt.dataTransfer.files[i];

        if (
          typeof FileReader !== 'undefined' &&
          file.type.indexOf('image') > -1
        ) {
          const reader = new FileReader();

          reader.onload = function (evt) {
            if (!evt.target) return;
            onFile(evt.target.result as string);
          };

          (function (i, file, reader) {
            setTimeout(function () {
              reader.readAsDataURL(file);
            }, i * 15);
          })(i, file, reader);
        }
      }
      evt.preventDefault();
    },
    false
  );
}

function ColorSwatchHSL(props: { hsl: number[] }) {
  const h = props.hsl[0] * 360;
  const s = props.hsl[1] * 100 + '%';
  const l = props.hsl[2] * 100 + '%';

  return (
    <div
      className='w-12 h-12 ring-gray-800 ring-1'
      style={{
        backgroundColor: `hsl(${h}, ${s}, ${l})`,
      }}
    ></div>
  );
}

function GradientSwatchHSL(props: { hsl: number[] }) {
  const h = props.hsl[0] * 360;
  const s = props.hsl[1] * 100 + '%';
  const l = props.hsl[2] * 100 + '%';

  return (
    <div
      className='w-50 h-8'
      style={{
        background: `linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 0%, hsl(${h}, ${s}, ${l}, 0.4) 100%)`,
      }}
    ></div>
  );
}

function isCloseToGrayscale(hsl: number[]): boolean {
  return hsl[1] < 0.1 || hsl[2] < 0.1 || hsl[2] > 0.9;
}

function ImageDisplay(props: { src: string }) {
  const [hsl, setHsl] = useState<number[]>([]);
  const [rgb, setRgb] = useState<number[]>([]);
  const [isGrayscale, setIsGrayscale] = useState<boolean>(false);
  const [timing, setTiming] = useState<{
    loadMs: number;
    extractMs: number;
  } | null>(null);

  useEffect(() => {
    async function exec() {
      const start = Date.now();
      const fac = new FastAverageColor();
      const result = await fac.getColorAsync(props.src, {
        algorithm: 'sqrt',
        ignoredColor: [
          // r, g, b, a, threshold
          [255, 255, 255, 255, 5],
          [0, 0, 0, 255, 5],
        ],
      });
      const extractMs = Date.now() - start;
      const rgb = result.value;
      const hsl = rgbToHsl(rgb[0], rgb[1], rgb[2]);

      console.log('result', result, 'hsl', hsl);

      setTiming({ loadMs: -1, extractMs });
      setHsl(hsl);
      setRgb(rgb);
      setIsGrayscale(isCloseToGrayscale(hsl));
    }

    exec();
  }, [props.src]);

  const DEFAULT_COLOR_BG_HSL_0_1 = [43 / 360, 55 / 100, 42 / 100];

  const rgba040logo = isGrayscale
    ? `rgba(255, 255, 255, 0.4)`
    : `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0.4)`;

  const rgba100logo = isGrayscale
    ? `rgba(255, 255, 255, 1)`
    : `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 1)`;

  return (
    <>
      <div className='flex gap-2'>
        <div>
          <img className='w-25' src={props.src} alt='loaded via user input' />
        </div>
        <ul>
          <li>Extract Time (includes load time): {timing?.extractMs}ms</li>
        </ul>
        <GradientSwatchHSL hsl={hsl} />
        {isGrayscale ? (
          <GradientSwatchHSL hsl={DEFAULT_COLOR_BG_HSL_0_1} />
        ) : (
          <></>
        )}
        <GamePlayScoreboardTextHeader
          rgba040={rgba040logo}
          rgba100={rgba100logo}
          orgLogoUrl={props.src}
          orgName='Org Name Here'
        />
      </div>
      <div className='flex gap-2'>
        <ColorSwatchHSL hsl={hsl} />
        {isGrayscale ? <>IS GRAYSCALE USE OVERRIDE</> : <>Ok!</>}
      </div>
    </>
  );
}

export function LogoColorExtraction() {
  const ref = useRef<HTMLDivElement>(null);
  const [urls, setUrls] = useState<string[]>([]);

  useLayoutEffect(() => {
    if (ref.current) {
      initDrop(ref.current, (fileAsDataUrl) => {
        setUrls((urls) => {
          return [...urls, fileAsDataUrl];
        });
      });
    }
  }, []);

  return (
    <div className='text-white'>
      <div ref={ref} className='w-64 h-64 bg-lp-gray-005'>
        Drop images here
      </div>
      <div className='flex flex-col gap-4'>
        {urls.map((url) => (
          <ImageDisplay key={url} src={url} />
        ))}
      </div>
    </div>
  );
}
